











import {
  Component,
  Vue
} from "vue-property-decorator";

import BikePicker from "@/components/BikePicker/BikePicker.vue";
import Footer from "@/components/Website/Footer/Footer.vue";
import Navigation from "@/components/Website/Navigation/Navigation.vue";

@Component({
  components: {
    BikePicker,
    Footer,
    Navigation
  }
})
export default class Home extends Vue {}
