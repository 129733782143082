










import { Component, Vue } from "vue-property-decorator";
import PerfectBike from "@/components/PerfectBike/PerfectBike.vue";

import Footer from "@/components/Website/Footer/Footer.vue";
import Navigation from "@/components/Website/Navigation/Navigation.vue";

@Component({
  components: {
    PerfectBike,
    Footer,
    Navigation,
  }
})
export default class  extends Vue {
    public imagelink1 = "features/bikerbuddy.jpg";
}
