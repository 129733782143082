











import {
  Component,
  Vue
} from "vue-property-decorator";

import Quiz from "@/components/Quiz/Quiz.vue";
import Footer from "@/components/Website/Footer/Footer.vue";
import Navigation from "@/components/Website/Navigation/Navigation.vue";

@Component({
  components: {
    Quiz,
    Footer,
    Navigation
  }
})
export default class Home extends Vue {}
