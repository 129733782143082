










import { Component, Vue } from "vue-property-decorator";
import CatOverview from "@/components/CatOverview/CatOverview.vue";

import Footer from "@/components/Website/Footer/Footer.vue";
import Navigation from "@/components/Website/Navigation/Navigation.vue";


@Component({
  components: {
    Footer,
    Navigation,
    CatOverview
  }
})
export default class Home extends Vue {

}
