






















import {
  Component,
  Vue
} from "vue-property-decorator";
import Profile from "@/components/LicenseProfile/LicenseProfile.vue";

import Footer from "@/components/Website/Footer/Footer.vue";
import Navigation from "@/components/Website/Navigation/Navigation.vue";

@Component({
  components: {
    Profile,
    Footer,
    Navigation
  }
})
export default class extends Vue {}
